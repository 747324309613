.Halden {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0a4040;
  color: white;
  letter-spacing: 2px;
}

.halden__heading {
  font-size: 4rem;
  margin: 0;
}

.halden__subheading {
  margin: 0;
}

.halden__image {
  height: fit-content;
  padding-right: 30px;
}

.halden__button {
  background: none;
  border: none;
  width: 3rem;
  height: 3rem;
  margin-right: 10px;
}

.halden__button:hover {
  cursor: pointer;
}

.halden__icon {
  font-size: 2rem;
  color: white;
  width: 2rem;
  height: 2rem;
}

.halden__icon--selected {
  color: #00ffff;
}

.halden__icons {
  display: flex;
  margin-top: 20px;
}

.halden__wrapper {
  display: flex;
  align-items: flex-end;
  width: 100vw;
}

.halden__video {
  width: 12.5rem;
  height: 9.375rem;
}

.halden__video-container {
  width: 12.5rem;
  height: 9.375rem;
}

.halden__image-wrapper {
  width: 30vw;
  display: flex;
  justify-content: flex-end;
}

.halden__right-wrapper {
  width: 60vw;
}

.halden__items {
  display: flex;
  flex-wrap: wrap;
  height: 40vh;
  overflow-x: hidden;
  align-content: flex-end;
}

.halden__items-inner {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-top: auto;
}

.halden__videos--not-loaded {
  display: none;
}

.halden-videos__loader {
  overflow: hidden;
}

.halden__mobile-head-wrapper {
  display: none;
}

.itch {
  width: 100%;
}

@media (max-width: 600px) {
  .halden__wrapper {
    height: 100vh;
  }

  .halden__image-wrapper {
    display: none;
  }

  .halden__right-wrapper {
    width: 100vw;
    margin: 1rem;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
  }

  .halden__items {
    height: 55vh;
  }

  .halden__mobile-head-wrapper {
    display: flex;
    height: 100%;
    align-items: flex-end;
  }
  .halden__mobile-head {
    width: 100%;
  }
}
