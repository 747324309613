#root {
  height: 100vh;
}

.App {
  font-family: VT323, monospace;
}

.mini-projects-header {
  text-align: left;
  color: white;
}

p {
  font-size: 25px;
}

.MoodySoup {
  background-image: url(https://i.imgur.com/WQJYxQi.png);
  color: white;
  background-repeat: no-repeat, repeat;
  background-color: #f59988;
}

.Page {
  height: calc(100% - 100px);
}

.Team {
  box-sizing: border-box;
  color: #ea6a8b;
  background-color: #c6e5d9;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}

.team-wrapper {
  height: calc(55vh - 100px);
  display: flex;
  justify-content: center;
  padding: 25px;
  align-items: flex-end;
  background: linear-gradient(180deg, #ffc0cb00 80%, #ea6a8b 20%);
}

.megan {
  background-color: pink;
}

.witch {
  width: 306px;
}

.game-panel {
  height: 17vh;
  opacity: 0.7;
}

.game-panel__label {
  height: 100%;
}

.game-panel:hover,
.game-panel:focus,
.game-panel:focus-within {
  opacity: 1;
}

.HatsAndCats {
  background-image: url(https://i.imgur.com/v8wfB7z.png);
  color: white;
  background-repeat: repeat, repeat;
  background-color: #630079;
  animation: animatedBackground 40s linear infinite;
  height: fit-content;
}

.LunarHeist {
  background-image: url(https://i.imgur.com/rynABBR.png);
  color: white;
  background-repeat: repeat, repeat;
  background-color: #630079;
  background-position: center;
  background-size: cover;
}

.DontBugMe {
  background-image: url(https://i.imgur.com/VnhKrOZ.png);
  background-position: 0px 0px;
  background-size: 10px;
  background-repeat: repeat-x;
  animation: animatedBackground 40s linear infinite;
  background-size: cover;
}

.StumbleAndStew {
  background-image: url(https://i.imgur.com/UbGXoOc.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #381a4d;
}

.CatGotYourTongue {
  background-image: url(https://i.imgur.com/PCVRMBY.gif);
  background-repeat: repeat-x;
  background-size:  100%;
  background-color: #654892;
  background-position-x: center;
  background-position-y: center;
}

.Solander {
  background-image: url(https://i.imgur.com/HDMPgQj.png);
  background-repeat: repeat-x;
  background-size: contain;
  background-color: #382f34;
  background-position-x: center;
  background-position-y: center;
}

.Vimboy {
  background: url(https://i.imgur.com/XRpbGuq.png);
  background-size: cover;
  color: white;
}

.mini-games {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.mini-games hr {
  padding: 0;
  margin: 0;
  border-width: 2px;
  border-style: solid;
  border-color: #ffffff82;
}

.mini-games a {
  text-decoration: none;
}

.vimboy-title {
  font-size: 40px;
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .moody-soup-logo {
    height: 240px;
    background-size: 100%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .moody-soup-image {
    width: 250px;
    height: auto;
  }
}

.moody-soup-image {
  border-radius: 15%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .moody-soup-description {
    width: inherit;
  }
}

.moody-soup-description {
  width: 400px;
}

.gallery {
  background-color: #ececec59;
  padding-bottom: 100px;
  border-top: 10px solid white;
}

.gallery-header {
  width: 100%;
  text-align: center;
}

.description {
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
  animation: fadein 2s ease-in;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .description {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.bass-logo {
  height: 80px;
}

body {
  background-color: #282c34;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-link {
  color: #61dafb;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.main-header {
  animation: float 6s ease-in-out infinite;
  padding-top: 100px;
}

.logo {
  background: url(octobass.svg);
  width: 600px;
  height: 400px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .logo {
    background: url(simple-octobass.svg);
    width: 150px;
    height: 240px;
    background-size: 100%;
  }
}

.game-image {
  object-fit: cover;
  width: 100px;
  height: 100px;
  padding: 10px;
}

.fade-in:hover {
  transform: scale(1.1);
}

.fade-in--no-delay:hover {
  transform: none;
}

.fade-in {
  animation: fadein 2s ease-in;
  animation-fill-mode: forwards;
  opacity: 0;
}

.game-images {
  padding-bottom: 30px;
}

.fade-in:nth-child(1) {
  animation-delay: 0.5s;
}
.fade-in:nth-child(2) {
  animation-delay: 1s;
}
.fade-in:nth-child(3) {
  animation-delay: 1.5s;
}

.fade-in--no-delay:nth-child(1) {
  animation-delay: 0s;
}
.fade-in--no-delay:nth-child(2) {
  animation-delay: 0.5s;
}
.fade-in--no-delay:nth-child(3) {
  animation-delay: 1s;
}

.links {
  border-top: #0000002e solid 2px;
  width: 100%;
}

.spin {
  animation: spin linear 3s infinite;
  animation-fill-mode: both;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.link {
  padding: 20px;
  color: #f4ead5;
}

.link-inverse {
  padding: 20px;
  color: #ea6a8b;
  text-decoration: none;
}

.font {
  font-family: VT323, monospace;
}

.link-inverse:hover {
  color: #f4ead5;
}

.link:hover {
  color: #ea6a8b;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9;
  }
}

.flex-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-item {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.slideRight {
  animation-name: slideRight;
  -webkit-animation-name: slideRight;

  animation-duration: 1s;
  -webkit-animation-duration: 1s;

  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;

  visibility: visible !important;
}

@keyframes slideRight {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.slideRight2 {
  animation-name: slideRight2;
  -webkit-animation-name: slideRight2;

  animation-duration: 1s;
  -webkit-animation-duration: 1s;

  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;

  visibility: visible !important;
}

@keyframes slideRight2 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.image-loading {
  opacity: 0;
}
.gallery-icon {
  padding: 10px;
  padding-top: 30px;
}

.gallery-icon__selected {
  color: #ea6a8b;
}

.team-panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 33vw;
  align-items: center;
  justify-content: space-between;
}

.team-info-wrapper {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0 10px;
  height: calc(35vh - 100px);
}
.team-info {
  padding: 5px 20px;
  max-width: 800px;
  background-color: azure;
  border-radius: 18px;
  text-align: center;
  margin: 0 auto;
  overflow: auto;
  max-height: calc(35vh - 100px);
  border: solid 10px;
}

.team-info h1 {
  font-size: 2rem;
  font-weight: normal;
}

.team-member-button {
  background-color: transparent;
  border: none;
  height: 100%;
  cursor: pointer;
}

.team-member-image {
  height: 100%;
}

.team-member-button:hover,
.team-member-button--active {
  transform: scale(110%);
}

.team-link {
  font-size: 2rem;
  padding-top: 0;
}

.nav-bar {
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 2rem;
}

.nav-bar__right {
  margin-left: auto;
}

.nav-bar__logo {
  display: flex;
  justify-content: center;
}

@media (max-width: 790px) {
  .team-member-image {
    width: 20vw;
    height: auto;
  }

  .team-member-button {
    height: auto;
  }
}

@media (min-height: 600px) and (max-width: 790px) {
  .team-info-wrapper {
    max-height: calc(60vh - 100px);
  }

  .team-info {
    max-height: calc(60vh - 100px);
  }

  .team-wrapper {
    height: fit-content;
  }

  .team-member-image {
    width: 20vw;
    height: auto;
  }

  .team-member-button {
    height: auto;
  }
}
